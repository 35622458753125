<template>
<div class="container" id="terms">
<h1 class="text-center">Terms and conditions</h1>
<h2>1. The Promoter</h2>

<p>The Promoter is Competition Mania Ltd of Made Simple Group, 20-22 Wenlock Road, London, N1 7GU, Company Number 12792356.</p>

<p>If you wish to contact us for any reason, please contact us on <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a></p>

<h3>The Competition</h3>

<p>2.1. These terms and conditions apply to all competitions listed on the Promoter’s website at <a href="https://www.competitionmania.co.uk/" >https://www.competitionmania.co.uk/</a> (the ‘’Website’’)</p>

<p>2.2. All competitions are skill-based competitions and a fee is payable each time you enter, unless postal entry route is chosen.</p>

<p>2.3. To be entered in competition, everyone who enters the competition (an ‘’Entrant’’) will be required to correctly answer or solve a problem set by the Promoter (the ‘’Competition Question’’). Where an incorrect answer is provided, the entrant will be charged, but not entered into the competition. </p>

<p>a) You can register an account on Promoter’s website: <a href="http://www.competitionmania.co.uk" >www.competitionmania.co.uk</a></p>

<p>i) To register an account, you will be asked to provide your name, last name, email address, mobile number and delivery address, which will be used to post your competition prize, where applicable. Please ensure your account details are up to date to avoid any issues receiving your prize. </p>

<p>b) You cannot have more than one account per person. Abuse of this limit will not be tolerated and if the Promoter has reasonable grounds to believe that an individual is exceeding this limit, it reserves the right at its sole discretion to void any orders or award any prize to the next closest entrant. </p>

<p>c) Competition Entries (tickets) are limited to 25 per person or whatever limit is placed on the competition page. Please read this carefully on the individual product page prior to entering the competition. Any abuse of this will not be tolerated and if the Promoter has reasonable grounds to believe that you are exceeding this limit, for instance, through various accounts, it reserves the right at its sole discretion to void any orders or award any prize to the next closest entrant on a chronological basis. </p>

<p>2.4. Entrants must be 18 years old or over.</p>

<br>



<div id="howtoplay">
<h2>3. How to Enter</h2>

<p>3.1. The Competition will run from and including the opening and closing dates specified on the Website. These dates shall be referred to as the ‘’Opening Date’’ and ‘’Closing Date’’ respectively. All times and dates are the times and dates in London, England.</p>

<p>3.2. Under exceptional circumstances, the Promoter reserves the right to change the Opening and Closing Dates. If the Promoter does change the Opening Date and/or the Closing Date of a competition, the new details will be displayed on the Website and through social media posts via Promoter Facebook page.</p>

<p>3.3. All competition entries must be received by the Promoter by no later than the specified time on the Closing Date. All competition entries received after the specified time on the Closing date are automatically disqualified and no refund will be given. Postal entries must be received 2 working days before the Closing Date. </p>

<p>3.4. The maximum number of entries to the competition will be stated on the Website. The number of entries you can make may be limited if the maximum number of entries is reached.</p>

<h3 id="howtoenter">3.5. To enter the competition:</h3>

<p>a) Go to the Website and view the Competition Question;</p>

<p>b) Select your answer to the competition question and required number of entries; then</p>

<p>c) Complete the checkout process and submit the online entry form (see free method below); then</p>

<p>d) Complete the payment to receive your order confirmation. </p>

<p>3.6. All entries must be submitted in the English language. Entries in languages other than English will automatically be disqualified and no refund will be given.</p>

<p>3.7. The Promoter will send confirmation that your entry has been received and confirm whether your answer to the Competition Question is correct.</p>

<p>3.8. The Promoter will not accept responsibility for competition entries that are not successfully completed, are lost or are delayed regardless of cause, including, for example, as a result of any equipment failure, technical malfunction, systems satellite, network, server, computer hardware or software failure of any kind.</p>

<p>3.9. By purchasing entries and submitting a competition entry, you are entering into a contract with the Promoter and are agreeing to be bound by these terms and conditions. </p>

<h3 id="freeentry">3.10. You may enter the competition for free by complying with the following conditions:</h3>

<ol ><li><p>Register an account on our Website;</p>

</li>
<li><p>Send your entry by first class post to the Promoter at the following address: </p>

</li>
</ol><p>Competition Mania Ltd, Made Simple Group, 20-22 Wenlock Road, London, N1 7GU</p>

<ol start="3"><li><h3>Include with your entry the following information:</h3>

</li>
<ol ><li><p>Your full name;</p>

</li>
<li><p>Your address;</p>

</li>
<li><p>Your contact telephone number;</p>

</li>
<li><p>Email address registered with your account on the Website;</p>

</li>
<li><p>Your chosen competition (including Competition Code - found in the description of chosen competition) and your answer to the Competition Question;</p>

</li>
<li><p>Agreement to Terms and Conditions.</p>

</li>
</ol><li><p>Incomplete or Illegible entries will be disqualified;</p>

</li>
<li><p>You may make multiple free entries for any competition (up to any limit placed on entries by the Promoter), but each free entry must be submitted and posted to the Promoter separately. Bulk entries in one envelope will not be accepted as multiple entries. If a bulk entry is received, it will be counted as a single entry;</p>

</li>
<li><p>By entering the competition, you are confirming that you are eligible to enter and accept these terms and conditions;</p>

</li>
<li><p>The Promoter will not acknowledge the receipt of your entry nor confirm if your answer to the Competition Question is correct;</p>

</li>
<li><p>If the number of paid entries reaches any cap or limit before your free entry is received, you will not be entered into the random draw;</p>

</li>
<li><p>All free entries are treated the same as paid entries; </p>

</li>
<li><p>If the entrants account cannot be identified from the details provided on the postal entry, then the competition-specific single entry will not be processed.</p>

</li>
</ol>
</div>
<h2>4. Choosing a Winner</h2>

<p>4.1. All Entrants, who correctly answer the Competition Question, will be placed into a draw and the winner will be chosen by random draw. The random draw will take place as soon as reasonably possible and, in any event, within 7 days of the Closing Date (‘’Draw Date’’).</p>

<p>4.2. All Entrants will have their username (used to sign up on the Website) and ticket numbers displayed on the product page of the competition they have entered. This will be visible for others to view. </p>

<p>4.3. The Promoter will announce the winner of each competition on their social media platforms and contact the winner directly via email or telephone number provided at the time of sign up. Please ensure you keep this up to date. </p>

<p>For help with entries, please email us at <a href="mailto:info@competitiomania.co.uk" >info@competitiomania.co.uk</a></p>

<h2>5. Eligibility </h2>

<p>5.1. Competitions are only open to all residents in the United Kingdom aged 18 over over, except:</p>

<ol ><li><p>Employees of the Promoter;</p>

</li>
<li><p>Employees of agents or suppliers of the Promoter, who are professionally connected with the competition or its administration; or,</p>

</li>
<li><p>members of the immediate families or households of (a) and (b) above.</p>

</li>
</ol><p>5.2. By entering the competition, you confirm that you are eligible to do so and eligible to claim any prize you may win. The Promoter may require you to provide proof that you are eligible to enter the competition. If you fail to provide the Promoter with any such proof or other information that they may require within a reasonable time, you may be disqualified from the competition. </p>

<p>5.3. The Promoter will not accept competition entries that are:</p>

<ol ><li><p>automatically generated by compute; or</p>

</li>
<li><p>incomplete.</p>

</li>
</ol><p>5.4. The Promoter reserves all rights to disqualify you if your conduct is contrary to the spirit or intention of prize competition. </p>

<p>5.5. No refunds of the entry fee will be given in any event, including:</p>

<ol ><li><p>If, following your entry into the competition, you subsequently find out that you are not eligible to enter the competition or claim the Prize;</p>

</li>
<li><p>If, following your entry into the competition the eligibility criteria for entering the competition or claiming the Prize changes and you are no longer eligible; or</p>

</li>
<li><p>If you are disqualified from the competition by the Promoter for any reason.</p>

</li>
</ol><p>5.6. Entrants can enter each competition as many times as they wish until the maximum number of entries per user have been submitted and until the maximum number of entries for the competition have been received. Entrants submitting free entries must submit each entry separately. Bulk entries, if received, will not be accepted and will only be counted as one single entry. </p>

<p>5.7. An Entry will be declared void (without any refund provided), if the Entrant engages in:</p>

<p>a) Any form of fraud (actual or apparent);</p>

<p>b) Fraudulent misrepresentation;</p>

<p>c) Fraudulent concealment;</p>

<ol start="4"><li><p>Hacking or interference with the proper functioning of the Website; or</p>

</li>
<li><p>Amending, or unauthorised use of, any of the code that constitutes the Website.</p>

</li>
</ol><h2>6. The Prize</h2>

<p>6.1. The prize for the competition is described on the Website (the ‘’Prize’’). Details of the Prize are, to the best of the Promoter’s knowledge, information and belief, correct as at the Opening Date. </p>

<p>6.2. Prizes are subject to availability. The Promoter reserves the right to substitute the prize with a prie of equal or greater value. If any details of the Prize change, the Promoter will endeavour to update the Website as soon as reasonably possible.</p>

<p>6.3. The Promoter makes no representations or gives no warranties about the Prize, its value, its condition or any other information on the Website. The Promoter makes no representations and gives no warranties that the information provided on the Website is accurate, complete or up to date. No insurance comes with the Prizes and the Promoter is not responsible for the Prize once it has been handed over to the Winner.</p>

<p>6.3.1. In the case that the Prize is a vehicle:</p>

<ol ><li><p>The Promoter will ensure a valid MOT, however; </p>

</li>
<li><p>No insurance comes with the Prize(s)</p>

</li>
<li><p>The Promoter will no longer have any responsibility over Prize(s) once handover is completed and;</p>

</li>
<li><p>No vehicle/road tax is included.</p>

</li>
</ol><p>6.3.2. The Promoter guarantees that all tech prizes are brand new for each draw, but cannot guarantee Prizes will have warranty for any breakages after they are handed over. </p>

<p>6.4. The Prize may be supplied by a third-party supplier (the ‘’Supplier’’). Details of the Supplier (if any) will be provided on the Website.</p>

<p>6.5. The Promoter reserves the right to substitute the Prize for an alternative cash prize (‘’Cash Prize) equal to the market value sought by the Promoter, including any original discount on price. The Cash Prize value will be displayed on each Competition page or available on request. The Prize can be substituted for a Cash Prize in the following circumstances:</p>

<p>a) The Prize, or a reasonable alternative, becomes unavailable;</p>

<p>b) Other circumstances beyond the reasonable control of the Promoter makes it necessary to do so;</p>

<p>c) The Winner opts for a Cash Prize instead of the Prize. Where all tickets do not sell, cash alternative is at reduced rate of 70% of the competition intake.</p>

<p>6.6. If the Winner wishes to take Cash Prize instead of the vehicle, the Promoter will seek the best price from local traders. Any cash values advertised on the Website are an estimated price and the cash alternative value will be confirmed at the current trade value of the vehicle at the time of the sale. The Promoter may make a reasonable deduction from the sale price to cover the time and administration costs required to undertake the task of security the best price for the Winner. The Winner is under no obligation to accept the cash alternative, if they disagree with the amount of the cash alternative They can accept the vehicle as the prize and then sell it privately. </p>

<p>6.7. The Prize is not negotiable or transferable.</p>

<h2>7. Winners</h2>

<p>7.1. The decision of the Promoter is final and no correspondence or discussion will be entered into.</p>

<p>7.2. The Winner’s username will be announced during the live draw. </p>

<p>7.3. The Promoter will contact the winner directly as soon as practicable after the Draw Date, using the email or telephone number provided with the competition entry. If the winner cannot be contacted or is not available, or has not claimed the Prize 14 days after the Draw Date, the Promoter reserves the right to offer the Prize to another eligible entrant (‘’The Alternate Winner’’) selected at random in the same method as before from the remaining correct entries received before the Closing Date. The Alternate Winner shall have 14 days from notification of their status by the Promoter to communicate their acceptance of the Prize. This process shall continue until a winner accepts the Prize.</p>

<p>7.4. The Promoter must either publish or make available information that indicates that a valid award took place. To comply with this obligation, the Promoter will publish the full name and county/town of residence of major prize winners on the Website.</p>

<p>7.5. If you object to any or all of your full name, county/town of residence and winning entry being published or made available, please contact the Promoter at <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a> prior to the Closing Date. In such circumstances, the Promoter must still provide the information to the Advertising Standards Authority on request. </p>

<h2>8. Claiming the Prize</h2>

<p>8.1. You must claim the Prize personally. The Prize may not be claimed by a third party on your behalf. Details of how the Prize will be delivered to you (or made available for collection) are published on the Website, or available on request. </p>

<p>8.2. If your personal details, including contact information, changes at any time you should notify the Promoter as soon as reasonably possible. Notifications should be sent to the Promoter via email to <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a>. Notifications must include details of the competition you have entered, your old details and your new details. If your details change within 10 days of the Closing Date, the Promoter will use your old details if it needs to try to contact you.</p>

<p>8.3. Any Cash Prize will be transferred directly to the Winners nominated bank account. The Winner must provide evidence that it is the sole or joint beneficiary of the bank account. Failure to do so within 14 days will result in disqualification from the competition and the Winner forfeiting the prize. In such circumstances, the Promoter reserves the right to offer the prize to the next eligible Entrant selected from the correct entries that were received before the Closing Date. </p>

<p>8.4. The Promoter does not accept any responsibility and is not liable to pay any compensation if you are unable to or do not take up the prize.</p>

<p>8.4.1. Limitation of liability insofar as is permitted by law, the Promoter, its agents or distributors will not in any circumstances be responsible or liable to compensate the Winner or accept any liability for any loss, damage, personal injury or death occurring as a result or taking up the Prize except where it is caused by the negligence of the Promoter, its agents or distributors or that of their employees. Your statutory rights are not affected.</p>

<p>8.4.2. Data Protection and Publicity.</p>

<p>8.4.3. By entering the competition, you agree that any personal information provided by you with the competition entry may be held and used only the Promoter or its agents and suppliers to administer the competition or as otherwise set out in the Promoter’s Privacy Policy <a href="http://www.competitionania.co.uk/privacy-policy" >www.competitionania.co.uk/privacy-policy</a>, a copy of which is available on the Website.</p>

<p>8.4.4. If you are the Winner of the competition, you agree that the Promoter may use your name, image and county of residence to announce the Winner of this competition. You further agree to participate in any reasonable publicity required by the Promoter.</p>

<p>8.4.5. If you do not wish to participate in any publicity, you must notify the Promoter prior to the Closing Date. This will not affect your chances of winning the Prize. If you do not agree to participate in any publicity about the competition, we may still provide your details to the Advertising Standards Authority. This is a legal requirement that we must comply with to prove that the competition has been properly administered and the Prize awarded. </p>

<p>8.4.6. If you are the Winner of the competition, you may be required to provide further personal information and proof of identity in order to confirm your eligibility to claim the Prize and transfer ownership of the Prize to you. You consent to the use of your information in this way. You are entitled to request further details about how your personal information is being used. You may also withdraw your consent to your personal information being used in such a way, but by doing so you may prevent the Prize being transferred to you. In such circumstances, you will be deemed to have withdrawn from the competition and forfeit the Prize. You will not be entitled to any refund of your entry fee. The Promoter reserves the right to offer the Prize to the next eligible Entrant selected at random from the correct entries that were received before the Closing Date.</p>

<p>8.5. Please note that under data protection laws, you are entitled to request that the Promoter does not contact you and removes your details from its database. If you make such a request, you will be withdrawing from the competition as it will not be possible to contact you in the event that you are the Winner. You will not be entitled to any refund of any entry fee, if you withdraw from the competition. If you do not wish any of your personal details to be used by the Promoter for promotional purposes, please email the Promoter at <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a> prior to the Closing Date.</p>

<p>8.6. If the Prize is a vehicle, the winner has completed all eligibility checks, the Prize will be transferred to the Winner by the Promoter using the V5 for each vehicle. This must be completed before the vehicle is handed over.</p>

<h2>9. General </h2>

<p>9.1. The Promoter reserves the right to amend these terms and conditions from time to time. The latest version of these terms and conditions will be available on the Website.</p>

<p>9.2. If there is any reason you believe that there has been a breach of these terms and conditions, the promoter may, at its sole discretion, reserve the right to exclude you from participating in the competition and any future competitions.</p>

<p>9.3. The Promoter reserves the right to hold void, suspend, cancel, or amend the Prize Competition where it becomes necessary to do so. </p>

<p>9.4. The competitions on the Website are in no way sponsored, endorsed, administered or associated with Facebook. By entering the competitions, Entrants agree that Facebook has no liability and is not responsible for the administration or promotion of the competitions.</p>

<p>9.5. These terms and conditions shall be governed by English law, and the parties submit to the exclusive jurisdiction of the courts of England and Wales.</p>

<p>9.6. You should print a copy of these terms and conditions and keep them for your records.</p>

<br>
</div>
  <Footer/>
</template>



<script>
import Footer from '../components/global/Footer'

export default {
    components: {
        Footer
    }
}
</script>